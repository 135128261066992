<template>
    <div class="home_section5_container" :class="{ en: isen }">
        <h1
            class="home_section5_title wow animate__animated animate__aplayzFadeInUp animate__fast"
            :class="{ en: isen }"
            data-wow-offset="30"
        >
            <slot name="homesection5title">
                고객사
            </slot>
        </h1>
        <p
            class="home_section5_paragraph wow animate__animated animate__aplayzFadeInUp animate__fast"
            :class="{ en: isen }"
            data-wow-offset="35"
        >
            <slot name="homesection5paragraph">
                대기업 및 국내 유명 프랜차이즈 기업이 어플레이즈와 함께 <br />
                브랜드 이미지에 최적화된 음악을 플레이하고 있습니다.
            </slot>
        </p>
        <p
            class="home_section5_paragraph_mobile wow animate__animated animate__aplayzFadeInUp animate__fast"
            :class="{ en: isen }"
            data-wow-offset="35"
        >
            <slot name="homesection5paragraphmo">
                대기업 및 국내 유명 프랜차이즈 기업이 <br />
                어플레이즈와 함께 브랜드 이미지에 최적화된 <br />
                음악을 플레이하고 있습니다.
            </slot>
        </p>
        <ul
            class="home_section5_tab dp_flex wow animate__animated animate__aplayzFadeInUp animate__fast"
            :class="{ en: isen }"
            data-wow-offset="40"
        >
            <li class="home_section5_tab_item cursor" @click="selectTab('tab1')" :class="{ active: tabActive === 'tab1' }">
                <slot name="homesection5tabitem1">
                    상업 공간
                </slot>
            </li>
            <li
                class="home_section5_tab_item cursor second"
                @click="selectTab('tab2')"
                :class="{ active: tabActive === 'tab2' }"
            >
                <slot name="homesection5tabitem2">
                    복합 공간
                </slot>
            </li>
        </ul>

        <div
            class="slide_container_list wow animate__animated animate__aplayzFadeInUp animate__fast"
            data-wow-offset="45"
            v-show="tabActive === 'tab1'"
        >
            <div id="homesection5swiper1" class="swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in slideList1" :key="'swiper1' + index">
                        <div class="dp_flex flex_column slide_item">
                            <img class="slide_item_img" :src="item.imgUrl" :alt="item.text" />
                            <p v-if="!isen" class="align_center slide_item_text flex_justify_center" v-html="item.text"></p>
                            <img v-else class="slide_item_brandimg" :src="item.brandImg" :alt="item.text" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slide_container_list" v-show="tabActive === 'tab2'">
            <div id="homesection5swiper2" class="swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in slideList2" :key="'swiper2' + index">
                        <div class="dp_flex flex_column slide_item">
                            <img class="slide_item_img" :src="item.imgUrl" :alt="item.text" />
                            <p v-if="!isen" class="align_center slide_item_text flex_justify_center" v-html="item.text"></p>
                            <img v-else class="slide_item_brandimg" :src="item.brandImg" :alt="item.text" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { WOW } from 'wowjs';
import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';
// import Swiper styles
import 'swiper/swiper.css';
import { cloneDeep } from 'lodash-es';
export default {
  name: 'HomeSection5',
  props: {
    isen: {
      type: Boolean,
      required: false,
      default: false
    },
    slide1list: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            text: '테라로사', // 테라로사
            imgUrl: '/media/images/home/tab1_slide1.png'
          },
          {
            text: '애슐리퀸즈',
            imgUrl: '/media/images/home/tab1_slide2.png'
          },
          {
            text: '현대자동차 전시장 및 A/S 거점',
            imgUrl: '/media/images/home/tab1_slide3.png'
          },
          { text: '해비치호텔앤드리조트', imgUrl: '/media/images/home/tab1_slide4.png' },
          { text: 'LIVART', imgUrl: '/media/images/home/tab1_slide5.png' },
          {
            text: '역전할머니맥주',
            imgUrl: '/media/images/home/tab1_slide6.png'
          }
        ];
      }
    },
    slide2list: {
      type: Array,
      required: false,
      default: () => {
        return [
          { text: '현대엔지니어링 본사', imgUrl: '/media/images/home/tab2_slide1.png' },
          {
            text: 'SK가스 충전소',
            imgUrl: '/media/images/home/tab2_slide2.png'
          },
          {
            text: '팩토리얼 성수',
            imgUrl: '/media/images/home/tab2_slide3.png'
          },
          { text: '현대캐피탈 본사 및 사업장', imgUrl: '/media/images/home/tab2_slide4.png' },
          {
            text: '현대제철 사업장',
            imgUrl: '/media/images/home/tab2_slide5.png'
          },
          {
            text: '현대자동차 본사/사업장/연구소',
            imgUrl: '/media/images/home/tab2_slide6.png'
          }
        ];
      }
    }
  },
  data () {
    return {
      tabActive: 'tab1',
      slideList1: [],
      slideList2: [],
      wow: null,
      swiper1: null,
      swiper2: null
    };
  },
  created () {
    this.slideList1 = cloneDeep(this.slide1list);
    this.slideList2 = cloneDeep(this.slide2list);
    const slide1Len = this.slideList1.length;
    const slide1LenTotal = this.slideList1.length * 3;
    // slide1 리스트 현재개수 *3 만큼 채우기
    for (let slide1Index = 0; slide1Index < slide1LenTotal; slide1Index++) {
      const mod = slide1Index % slide1Len;

      this.slideList1.push(this.slideList1[mod]);
    }

    const slide2Len = this.slideList2.length;
    const slide2LenTotal = this.slideList2.length * 3;
    // slide2 리스트 현재개수 *3 만큼 채우기
    for (let slide2Index = 0; slide2Index < slide2LenTotal; slide2Index++) {
      const mod = slide2Index % slide2Len;

      this.slideList2.push(this.slideList2[mod]);
    }
  },

  mounted () {
    this.wow = new WOW({
      live: false
    });
    this.wow.init();
    this.wow.sync();

    this.setSwiper1();
  },

  methods: {
    setSwiper1 () {
      this.swiper1 = new Swiper('#homesection5swiper1', {
        loop: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        autoplay: {
          delay: 0,
          disableOnInteraction: false
        },
        speed: 7000,
        allowTouchMove: false,
        modules: [Autoplay]
      });
    },
    setSwiper2 () {
      this.swiper2 = new Swiper('#homesection5swiper2', {
        loop: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        autoplay: {
          delay: 0,
          disableOnInteraction: false
        },
        speed: 7000,
        allowTouchMove: false,
        modules: [Autoplay]
      });
    },
    async selectTab (val) {
      if (this.tabActive !== val) {
        this.tabActive = val;
        await this.$nextTick();
        await this.$forceUpdate();

        if (this.tabActive === 'tab1') {
          this.setSwiper1();
        } else {
          this.setSwiper2();
        }
      }
    }
  }
};
</script>
<style scoped src="@/assets/css/home/homesection5.css"></style>
